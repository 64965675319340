<template>
  <modal
    transition="nice-modal-fade"
    width="680"
    :adaptive="true"
    :reset="true"
    height="auto"
    style="overflow-y: scroll; max-height: 680px"
    @before-open="beforeOpen"
    :name="modalName"
  >
    <div class="modal--wrapper photo--section">
      <section-loading :show="loading" />
      <div
        v-if="!commission || !commission.payment_photos || commission.payment_photos.length === 0"
      >
        <div class="modal--title">
          {{ $t('commission.modal.title') }}
        </div>
        <!--ini preview gambar-->
        <div
          class="photo--preview-wrapper"
          v-show="commissionPhotos && commissionPhotos.length > 0"
        >
          <photo-preview
            v-for="(photo, index) in commissionPhotos"
            :key="index"
            :index="index"
            :length="commissionPhotos.length"
            :photo="photo"
            :isFloorPlan="false"
            :noPrimary="true"
            removePhotoDispatch="transaction/form/REMOVE_COMMISSION_PHOTO"
          />
        </div>
        <vue-dropzone
          v-show="false"
          ref="photoDropzone"
          id="dropzone"
          :options="dropzoneOptions"
          @vdropzone-file-added="added"
          @vdropzone-sending="sending"
          @vdropzone-success="success"
          @vdropzone-complete="completed"
        />
        <div class="d-flex align-center justify-center mt-4">
          <v-btn
            type="button"
            color="primary"
            :disabled="commissionPhotos >= 15"
            class="btn btn-primary--light btn-block"
            @click="triggerDropzone"
          >
            {{ $t('project.upload') }}
          </v-btn>
        </div>
        <span class="val-error" v-if="validation.hasError('commissionPhotos')">{{
          validation.firstError('commissionPhotos')
        }}</span>
        <div
          class="d-flex align-center justify-center mt-4"
          v-if="commissionPhotos && commissionPhotos.length > 0"
        >
          <v-btn color="primary" class="bottom--button" @click="accept">
            {{ $t('commission.btn.change') }}
          </v-btn>
        </div>
      </div>
      <div v-else>
        <div class="modal--title">
          {{ $t('commission.seeProof') }}
        </div>
        <simple-photo-preview :photos="commission.payment_photos"></simple-photo-preview>
      </div>
    </div>
  </modal>
</template>

<script>
import { mapState } from 'vuex';
import HelperMixin from '@/mixins/helpers';
import { Validator } from 'simple-vue-validator';
const PhotoPreview = () => import('@/components/project-management/project/form/photo-preview.vue');
import SectionLoading from '@/components/content-loading/section-loading';
const SimplePhotoPreview = () => import('@/components/utils/simple-photo-preview');
import vue2Dropzone from 'nuxt-dropzone';

export default {
  name: 'modal-commission',
  mixins: [HelperMixin],
  components: {
    vueDropzone: vue2Dropzone,
    PhotoPreview,
    SectionLoading,
    SimplePhotoPreview,
  },
  data() {
    return {
      modalName: 'modal-commission',
      commission: null,
      addedPhoto: true,
      loading: false,
      dropzoneOptions: {
        url: `${this.$store.state.global.apiUrl}/api/file_content/upload_photo`,
        thumbnailWidth: 150,
        maxFilesize: 5,
        thumbnailMethod: 'contain',
        acceptedFiles: '.jpg,.png,.jpeg',
        // headers: {'X-CSRF-TOKEN': self.csrfToken},
        createImageThumbnails: true,
      },
    };
  },
  computed: {
    ...mapState({
      commissionPhotos: (state) => state.transaction.form.commissionPhotos,
    }),
  },
  validators: {
    commissionPhotos(value) {
      return Validator.value(value).required(
        this.$i18n.t('errors.transaction.commissionPhotos.required'),
      );
    },
  },
  methods: {
    beforeOpen(event) {
      this.commission = event.params.commission;
    },
    close() {
      this.$modal.hide(this.modalName);
    },
    triggerDropzone() {
      this.$refs.photoDropzone.dropzone.hiddenFileInput.click();
    },
    completed() {
      this.loading = false;
    },
    added(file) {
      this.loading = true;
      const dropzone = this.$refs.photoDropzone;
      const maxFile = 15;
      const listPhoto = this.commissionPhotos.length;
      const uploading = dropzone.getUploadingFiles().length;
      const queued = dropzone.getQueuedFiles().length;
      const totalPhoto = listPhoto + uploading + queued;
      this.addedPhoto = true;
      if (totalPhoto >= maxFile) {
        dropzone.removeFile(file);
        // eslint-disable-next-line no-undef
        this.loading = false;
        this.$swal(
          this.$t('errors.general.photo.maxTitle'),
          this.$i18n.t('errors.general.photo.maxMessage', {
            max: maxFile,
          }),
          'error',
        );
      }
    },
    // eslint-disable-next-line no-unused-vars
    sending(file, xhr, formData) {
      this.loading = true;
      xhr.setRequestHeader('Authorization', `Bearer ${this.$store.state.global.token}`);
    },
    success(file, response) {
      let img = file.previewElement.querySelector('img');
      this.$store.commit('transaction/form/ADD_COMMISSION_PHOTO', {
        src: img.src,
        uuid: response.data.photo_uuid,
        isPrimary: false,
      });
      let dropzone = this.$refs.photoDropzone;
      dropzone.removeFile(file);
    },
    async accept() {
      if (await this.$validate()) {
        await this.$store.dispatch('transaction/form/setCommissionToWithdrawable');
        await this.$swal(
          this.$i18n.t('general.success'),
          this.$i18n.t('transaction.changeCommission.successMsg'),
          'success',
        );
        this.close();
        await this.$store.dispatch('transaction/getDetailInitData', this.$route.params.uuid);
      } else {
        this.scrollToErrorSection(this.$el);
      }
    },
  },
};
</script>

<style scoped></style>
